<script setup lang="ts">
import { isError as isH3Error } from 'h3';

import { ClientBusinessError, createBusinessError } from '@/functions/error/views';
import { loadScript, loadScriptForAutoHeight, J_QUERY_AUTO_HEIGHT_REQUIRED_PATHS } from '@/utils';

import { ClientAccessOverloadError } from './functions/error/views/ClientAccessOverloadError';
import { createAccessOverloadError } from './functions/error/views/accessOverloadError';

import { onErrorCaptured, createError, useRoute, ref, onMounted } from '#imports';

const handleOnError = (err: Error) => {
  // createErrorを用いて作成されたエラーは、再throwする。
  if (isH3Error(err)) {
    throw err;
  }

  if (err instanceof ClientBusinessError) {
    throw createBusinessError({ errorCode: err.errorCode });
  }
  if (err instanceof ClientAccessOverloadError) {
    throw createAccessOverloadError({ errorCode: err.errorCode });
  }

  // 想定外のランタイムエラーは、別途createErrorエラー情報を作成しなおす。
  throw createError({ ...err, fatal: true });
};

onErrorCaptured(handleOnError);

const autoHeightLoaded = ref(false);
const { path } = useRoute();

onMounted(() => {
  if (
    !path.includes('/app_detail') &&
    !path.includes('/bank_transfer/') &&
    !path.includes('/BankAccountTransferEnd.html')
  ) {
    const jQuery = loadScript('/js/jquery-3.6.0.min.js');

    jQuery.addEventListener('load', () => {
      loadScript('/js/web_common.js');
      loadScript('/js/set_inflow_source.js');

      const jQueryAutoHeightRequiredPaths = J_QUERY_AUTO_HEIGHT_REQUIRED_PATHS.concat(
        J_QUERY_AUTO_HEIGHT_REQUIRED_PATHS.map((path) => `${path}/`)
      );

      if (jQueryAutoHeightRequiredPaths.includes(path)) {
        const autoHeight = loadScript('/js/jQueryAutoHeight.js');

        autoHeight.addEventListener('load', () => {
          autoHeightLoaded.value = true;

          if (!path.includes('/campaign')) {
            loadScriptForAutoHeight(path);
          }
        });
      }
    });
  }
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage :auto-height-loaded />
  </NuxtLayout>
</template>
