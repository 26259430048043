<script setup lang="ts">
import { BUSINESS_ERROR_CODE } from '../enums';

import { BUSINESS_ERROR_MAP, type BusinessErrorProps } from './enums';
import { SimpleErrorPage } from './layouts';

const error = BUSINESS_ERROR_MAP[BUSINESS_ERROR_CODE.ACCESS_OVERLOAD] as BusinessErrorProps;
</script>

<template>
  <SimpleErrorPage :error="error" :show-header="true" :show-button="false" />
</template>

<style scoped lang="scss"></style>
