import { BusinessErrorCode } from '../enums';

type AccessOverloadErrorProps = {
  errorCode: BusinessErrorCode;
};

/**
 * クライアント側でスローする業務エラー
 */
export class ClientAccessOverloadError extends Error {
  static {
    this.prototype.name = 'ClientAccessOverloadError';
  }
  errorCode: BusinessErrorCode;

  /**
   * コンストラクタ
   * @param message - エラーメッセージ
   * @param options - 継承元のErrorに渡したい情報
   */
  constructor(message = '', options: AccessOverloadErrorProps & ErrorOptions) {
    const { errorCode, ...rest } = options;
    // causeがあるときはErrorに渡される
    super(message, rest);
    this.errorCode = errorCode;
  }
}
