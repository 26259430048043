<script setup lang="ts">
import { computed } from 'vue';

import { UiButton, UiGlobalHeader } from '@/components/ui';
import { ROUTES } from '@/enums';

import { ErrorIcon } from '../components';
import { BusinessErrorProps } from '../enums';
import { navigateTo } from '#app';

import { useRouter } from '#imports';

const props = withDefaults(
  defineProps<{
    error: Omit<BusinessErrorProps, 'layout'>;
    showHeader?: boolean;
    showButton?: boolean;
  }>(),
  {
    showHeader: true,
    showButton: true,
  }
);

const router = useRouter();

const handleClickButton = () => {
  if (props.error.backTo) {
    router.push({ name: props.error.backTo });
  } else {
    navigateTo('/');
  }
};

const buttonLabel = computed(() => props.error.backToText ?? 'ホームへ');
</script>

<template>
  <div class="page">
    <UiGlobalHeader title="エラー" />
    <main class="body" :class="{ 'non-header': !showHeader, 'non-button': !showButton }">
      <div class="content">
        <div class="heading">
          <ErrorIcon :title="error.title" />
          <div class="messages">
            <p>{{ error.message }}</p>
          </div>
        </div>
        <div v-if="showButton" class="footer-button">
          <UiButton @click="handleClickButton">{{ buttonLabel }}</UiButton>
        </div>
      </div>
    </main>
  </div>
</template>

<style lang="scss" scoped>
.page {
  height: 100dvh;
}
.body {
  height: calc(100% - 44px);
  &.non-header {
    height: calc(100dvh - 74px);
  }
  &.non-button {
    height: calc(100dvh - 136px);
  }
}
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 72px;
}
.heading {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.messages {
  margin-top: 16px;
  white-space: pre-wrap;
  font-size: 1.4rem;
}
.footer-button {
  width: 100%;
  text-align: center;
}
</style>
