import { tryGetErrorData } from '@/views/error/utils';

import { BusinessErrorCode } from '../enums';

import { createError } from '#imports';

const ACCESS_OVERLOAD_ERROR = 'ACCESS_OVERLOAD_ERROR';

/**
 * ClientAccessOverloadErrorからH3Errorに変換された後のエラー
 */
export type AccessOverloadErrorData = {
  type: typeof ACCESS_OVERLOAD_ERROR;
  errorCode: BusinessErrorCode;
};

/**
 * 流量制御エラーを生成する
 * @param args - args
 */
export const createAccessOverloadError = (args: Omit<AccessOverloadErrorData, 'type'>) =>
  createError({
    fatal: true,
    data: {
      type: ACCESS_OVERLOAD_ERROR,
      ...args,
    },
  });

/**
 * 流量制御エラーの取得を試みる。取得できない場合はundefinedを返却する。
 * @param error - エラー情報
 */
export const tryGetAccessOverloadError = (error: unknown): AccessOverloadErrorData | undefined =>
  tryGetErrorData(error, ACCESS_OVERLOAD_ERROR);
