import axios, { type AxiosRequestConfig, type AxiosInstance, AxiosError } from 'axios';

import type { HttpClientInterface } from '@/types';
import { BUSINESS_ERROR_CODE, BusinessErrorResponseData } from '@/functions/error';
import { ClientBusinessError } from '@/functions/error/views/ClientBusinessError';
import { provide as logProvide } from '@/functions/log/views';
import { isClient } from '@/utils';

import { ClientAccessOverloadError } from '~/functions/error/views/ClientAccessOverloadError';

type ConstructorProps = {
  baseUrl?: string;
};

/**
 * HttpClient
 */
export class HttpClient implements HttpClientInterface {
  private client: AxiosInstance;

  /**
   * コンストラクタ
   * @param props - プロパティ
   * @param props.baseUrl - ベースURL
   */
  constructor(props: ConstructorProps = {}) {
    this.client = axios.create({
      baseURL: props.baseUrl || (isClient() ? undefined : process.env.NUXT_PUBLIC_API_BASE_URL),
      withCredentials: true,
    });
    this.client.interceptors.request.use((request) => {
      request.timestamp = performance.now();

      logProvide.debug({
        event: 'api',
        req: request.data,
        message: `Request is sent to ${request.url}`,
      });

      return request;
    });

    this.client.interceptors.response.use(
      (response) => {
        response.responseTime = performance.now() - (response.config.timestamp || 0);
        logProvide.info({
          event: 'api',
          res: response.data,
          duration: response.responseTime,
          message: `Response is received from ${response.config.url}`,
        });
        return response;
      },
      async (error) => {
        if (error instanceof AxiosError) {
          const isTimeout = error.code === 'ECONNABORTED';

          if (
            // NOTE: タイムアウト時に処理を分けたい場合はif文を分ける
            // タイムアウト時
            isTimeout ||
            // API側の応答がない、クライアント側のネットワーク起因でエラーになる場合
            !error.response ||
            // API側からレスポンスがあるも、HTTPステータスが400番台の場合
            error.response.status >= 400
          ) {
            // 業務エラー時
            if (error.response && error.response.status === 422) {
              /*
                NOTE:
                帳票ダウンロード時などresponseTypeにblobを指定した場合は
                エラーレスポンスのJSONもBlobと解釈してしまうため、await blob.text()で文字列を抽出する
               */
              const { errorCode } =
                error.request.responseType === 'blob'
                  ? (JSON.parse(await (error.response.data as Blob).text()) as BusinessErrorResponseData)
                  : (error.response.data as BusinessErrorResponseData);

              // 業務エラーを発生させる
              throw new ClientBusinessError(errorCode, { errorCode, cause: error.cause });
            } else if (error.response && error.response.status === 429) {
              const errorCode = BUSINESS_ERROR_CODE.ACCESS_OVERLOAD;
              // 流量制御エラーを発生させる
              throw new ClientAccessOverloadError(errorCode, { errorCode, cause: error });
            }

            throw error;
          }

          // 上記以外のエラーは正常
          return error.response;
        }

        throw error;
      }
    );
  }

  /**
   * get処理を行い、レスポンスデータを返却する
   * @param path - url
   * @param queryParams - クエリパラメータ
   * @param config - コンフィグ
   */
  async get<T>(path: string, queryParams?: Record<string, unknown>, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.client.get<T>(path, { ...config, params: queryParams }).catch((err) => {
      throw err;
    });
    return response.data;
  }

  /**
   * post処理を行い、レスポンスデータを返却する
   * @param path - url
   * @param requestBody - リクエストボディ
   * @param config - コンフィグ
   */
  async post<T>(path: string, requestBody: object, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.client.post<T>(path, requestBody, config).catch((err) => {
      throw err;
    });
    return response.data;
  }

  /**
   * delete処理を行い、レスポンスデータを返却
   * @param path - url
   * @param config - コンフィグ
   */
  async delete<T>(path: string, config?: object): Promise<T> {
    const response = await this.client.delete<T>(path, config).catch((err) => {
      throw err;
    });
    return response.data;
  }

  /**
   * put処理を行い、レスポンスデータを返却する
   * @param path - url
   * @param requestBody - リクエストボディ
   * @param config - コンフィグ
   */
  async put<T>(path: string, requestBody: object, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.client.put<T>(path, requestBody, config).catch((err) => {
      throw err;
    });
    return response.data;
  }

  /**
   * patch処理を行い、レスポンスデータを返却する
   * @param path - url
   * @param requestBody - リクエストボディ
   * @param config - コンフィグ
   */
  async patch<T>(path: string, requestBody: object, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.client.patch<T>(path, requestBody, config).catch((err) => {
      throw err;
    });
    return response.data;
  }
}
